import { mapState } from "vuex"
export default {
  computed: {
    ...mapState([
      "settings",
      "currentUserRole",
      "onboardings",
      "currentOnboardingIndex",
    ]),
    singleRestaurantSteps() {
      return [
        {
          name: "settings",
          title: "General Information",
          description:
            "Get started by filling out the basic details of your restaurant!",
        },
        {
          name: "restaurants",
          title: "Restaurants",
          description:
            "Fill in your restaurant details or sync with an existing provider for a quick-start.",
        },
        {
          name: "payments",
          title: "Payments",
          description:
            "Set up your preferred payment methods to ensure you can receive your funds.",
        },
        {
          name: "branding",
          title: "Branding",
          description:
            "Set up your logo, colors, and images to create a look that represents your restaurant.",
        },
      ]
    },
    multiRestaurantSteps() {
      return [
        {
          name: "settings",
          title: "General Information",
          description:
            "Let’s start by entering the general information about " +
            this.settings.organizationName +
            ".",
        },
        {
          name: "branding",
          title: "Branding",
          description:
            "Set up your app logo and colors to create a look that represents your brand.",
        },
        {
          name: "payments",
          title: "Payments",
          description:
            "Set up your payment provider to ensure you and your restaurants can get paid.",
        },
        {
          name: "charges",
          title: "Payment Settings",
          description: "Add automatic commission and set your tipping preferences.",
          substep: true,
        },
        {
          name: "restaurants",
          title: "Restaurants",
          description: "Add restaurants and invite them to complete their details.",
        },
      ]
    },
    multiRestaurantRestaurantSteps() {
      return [
        {
          name: "restaurants",
          title: "Restaurant Information",
          description:
            "Get started by filling out the basic details of your restaurant!",
        },
        {
          name: "branding",
          title: "Branding",
          description:
            "Add your logo and hero image to create a consistent look for your restaurant.",
        },
        {
          name: "payments",
          title: "Payment",
          description:
            "Complete your payment details to ensure you can receive your funds.",
        },
      ]
    },
  },
  methods: {
    getNextStep(step) {
      const currentIndex = this.getOnboardingSteps().findIndex(s => s.name === step)
      return this.getOnboardingSteps()[currentIndex + 1]?.name ?? "finish"
    },
    getPreviousStep(step) {
      const currentIndex = this.getOnboardingSteps().findIndex(s => s.name === step)
      return (
        this.getOnboardingSteps()[currentIndex - 1]?.to ?? {
          params: { step: null },
        }
      )
    },
    getOnboardingSteps() {
      const steps = this.settings.singleBrandEnabled
        ? this.singleRestaurantSteps
        : this.currentUserRole === "Admin"
        ? this.multiRestaurantSteps
        : this.multiRestaurantRestaurantSteps

      return steps?.map(step => {
        step.completed =
          this.onboardings[this.currentOnboardingIndex]?.steps[step.name]
            ?.completed ?? false
        return step
      })
    },
    getStepTitle(step) {
      return this.getOnboardingSteps().find(s => step === s.name)?.title ?? "Finish"
    },
    getStepDescription(step) {
      return this.getOnboardingSteps().find(s => step === s.name)?.description ?? ""
    },
  },
}
