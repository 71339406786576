<template>
  <div>
    <v-card outlined class="pb-4">
      <v-card-subtitle class="font-weight-bold">Commission</v-card-subtitle>
      <v-card-text>
        <p class="mb-0">Would you like to charge a commission to the restaurants?</p>
        <v-row class="mb-n6">
          <v-col>
            <v-radio-group v-model="chargeCommission" dense row>
              <v-radio label="Yes" :value="true" />
              <v-radio label="No" :value="false" />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="chargeCommission" class="my-3 mb-n3">
          <v-col cols="3">
            <v-text-field
              v-model="organizationFee"
              label="Commission fee"
              outlined
              hide-details="auto"
              suffix="%"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="organizationFeeGST"
              label="GST on commission"
              outlined
              hide-details="auto"
              hint="GST is usually required."
              suffix="%"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="organizationFeePST"
              label="PST on commission"
              outlined
              hide-details="auto"
              hint="PST is only applicable in some provinces."
              suffix="%"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="organizationFeeTax"
              label="Other taxes on commission"
              outlined
              hide-details="auto"
              suffix="%"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-subtitle class="font-weight-bold">Tips</v-card-subtitle>
      <v-card-text>
        <p class="mb-0">
          Would you like to take a percentage of the tips before distributing the
          rest to the restaurants?
        </p>
        <v-row class="mb-n6">
          <v-col>
            <v-radio-group v-model="takeTips" dense row>
              <v-radio label="Yes" :value="true" />
              <v-radio label="No" :value="false" />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="takeTips" class="mt-3">
          <v-col cols="12">
            <v-text-field
              v-model="organizationTip"
              :label="`Percentage of tip to take`"
              outlined
              hide-details="auto"
              suffix="%"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <onboarding-footer next @submit="submitData" />
  </div>
</template>

<script>
import OnboardingFooter from "../OnboardingFooter.vue"
import { mapActions, mapState } from "vuex"

export default {
  name: "onboarding-charges",
  components: { OnboardingFooter },
  data() {
    return {
      // have to be converted to numbers later
      organizationFee: "",
      organizationFeeGST: "",
      organizationFeePST: "",
      organizationFeeTax: "",
      organizationTip: "",
      chargeCommission: false,
      takeTips: false,
    }
  },
  computed: {
    ...mapState(["settings"]),
  },
  mounted() {
    this.organizationFee = this.settings.organizationFee || ""
    this.organizationFeeGST = this.settings.organizationFeeGST || ""
    this.organizationFeePST = this.settings.organizationFeePST || ""
    this.organizationFeeTax = this.settings.organizationFeeTax || ""
    this.organizationTip = this.settings.organizationTip || ""
    this.chargeCommission =
      this.organizationFee !== "" ||
      this.organizationFeeGST !== "" ||
      this.organizationFeePST !== "" ||
      this.organizationFeeTax !== ""
    this.takeTips = this.organizationTip !== ""
  },
  methods: {
    ...mapActions(["updateOrganizationSettings"]),
    async submitData() {
      await this.updateOrganizationSettings({
        organizationFee: this.organizationFee,
        organizationFeeGST: this.organizationFeeGST,
        organizationFeePST: this.organizationFeePST,
        organizationFeeTax: this.organizationFeeTax,
        organizationTip: this.organizationTip,
      })
    },
  },
}
</script>
