<template>
  <v-card outlined class="mb-8">
    <v-card-text>
      <div>
        You finished onboarding! You can always adjust your settings through the
        <v-btn
          to="/settings/restaurants"
          fab
          rounded
          text
          small
          color="text-on-primary-bg"
        >
          <v-icon color="primary">mdi-cog</v-icon>
        </v-btn>
        icon.
      </div>
    </v-card-text>
    <onboarding-footer :next="isValid" />
  </v-card>
</template>

<script>
import OnboardingFooter from "../OnboardingFooter.vue"

export default {
  name: "onboarding-finish",
  components: { OnboardingFooter },
  data() {
    return {
      isValid: true,
    }
  },
}
</script>
