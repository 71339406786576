<template>
  <v-card
    outlined
    :color="active ? '' : 'light-background'"
    max-width="1000"
    :to="step.to"
  >
    <v-row align="center">
      <v-col class="shrink ml-6">
        <v-icon v-if="step.completed" color="green">
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <span
          v-else
          class="onboarding-circle text--lighttext"
          :class="active ? 'large' : ''"
        >
          {{ index + 1 }}
        </span>
      </v-col>
      <v-col class="grow">
        <v-card-title>{{ step.title }}</v-card-title>
        <v-card-subtitle v-if="!step.completed" class="text--lighttext">
          {{ step.description }}
        </v-card-subtitle>
      </v-col>
      <v-col class="shrink">
        <v-card-actions class="mr-8">
          <v-btn v-if="active" color="primary" class="mr-4" :to="routerLink">
            <span v-if="index === 0" style="white-space: normal">Get started</span>
            <span v-else>Continue</span>
          </v-btn>
          <v-btn
            v-else-if="step.completed"
            class="mr-4 align-self-center"
            :to="routerLink"
            text
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "onboarding-panel",
  props: {
    step: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    routerLink() {
      return {
        params: { step: this.step.name },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.onboarding-circle {
  border: solid 2px var(--v-lighttext-base);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(.large) {
    transform: scale(0.7);
  }
}
</style>
