<template>
  <div class="mx-auto pt-4">
    <div
      v-if="!$route.params.step"
      class="d-flex flex-column align-center justify-center"
    >
      <h2 class="text-uppercase page-title mb-4 mt-2">
        {{ getOnboardingTitle() }}
      </h2>
      <v-card outlined class="mt-8">
        <v-card-subtitle>
          Get started with onboarding. Follow the steps below to complete the
          onboarding process.
        </v-card-subtitle>
        <onboarding-panel
          v-for="(step, i) in onboardinSteps"
          :key="i"
          :step="step"
          :active="i === activeStepIndex"
          :index="i"
          :class="{ 'mb-1': i < onboardinSteps.length - 1 }"
        />
      </v-card>
    </div>
    <div v-else class="mx-auto" style="max-width: 1000px">
      <steps ref="steps" />
    </div>
  </div>
</template>

<script>
import OnboardingPanel from "./OnboardingPanel.vue"
import Steps from "./Steps/index.vue"
import { mapState, mapGetters } from "vuex"
import getOnboardingSteps from "./mixins"

export default {
  name: "onboarding",
  components: { OnboardingPanel, Steps },
  mixins: [getOnboardingSteps],
  data() {
    return {
      currentStepStatus: false,
      onboardinSteps: [],
    }
  },
  computed: {
    ...mapState(["currentUserRole", "settings", "restaurants"]),
    ...mapGetters(["currentOnboarding", "siteTitle"]),
    shouldSkipPayments() {
      return this.settings.paymentProvider === "square"
    },
    activeStepIndex() {
      return this.onboardinSteps.findIndex(step => !step.completed)
    },
  },
  watch: {
    "$route.params.step": {
      handler() {
        this.updateOnboardinSteps()
      },
      deep: true,
      immediate: true,
    },
    currentOnboarding: {
      handler() {
        if (this.shouldSkipPayments) {
          const paymentStepIndex = this.getOnboardingSteps().findIndex(
            step => step.id === "payments"
          )
          if (paymentStepIndex !== -1) {
            this.getOnboardingSteps().splice(paymentStepIndex, 1)
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getOnboardingSteps()
  },
  methods: {
    getOnboardingTitle() {
      let title = this.siteTitle + " Onboarding for "
      if (this.currentUserRole !== "Admin") {
        title += this.getRestaurantName(this.currentOnboarding.restaurantId)
      } else {
        title += this.settings.organizationName
      }
      return title
    },
    getRestaurantName(id) {
      return this.restaurants.find(restaurant => restaurant.id === id)?.name
    },
    updateOnboardinSteps() {
      this.onboardinSteps = this.getOnboardingSteps().filter(step => !step.substep)
    },
  },
}
</script>
