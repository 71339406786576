<template>
  <div class="mb-2">
    <v-card-subtitle class="pa-0">
      Are you using either Square POS or Deliverect?
    </v-card-subtitle>
    <p class="mt-2 text--lighttext">
      If so, we can import data from there to get you started faster. If not, you can
      enter it manually.
    </p>
    <!-- SHOW RESTAURANT IF EXISTS AND ENSURE IT SHOWS AFTER SYNC! -->
    <v-row class="mt-3">
      <v-col>
        <v-radio-group v-model="restaurantType" @change="checkComplete">
          <v-radio label="Square POS" value="square"></v-radio>
          <v-radio label="Deliverect" value="deliverect"></v-radio>
          <v-radio label="Enter manually" value="manual"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="restaurantType === 'square'" class="mt-3">
      <v-col>
        <v-btn color="primary" @click="linkSquare(currentRestaurant)">
          <v-icon class="mr-2">mdi-link</v-icon>
          Sync with Square
        </v-btn>
        <span class="mx-5 text--lighttext">OR</span>
        <v-btn color="light" @click="manualEdit = true">
          <v-icon class="mr-2">mdi-pencil</v-icon>
          Edit Manually
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="restaurantType === 'deliverect'" class="mt-3">
      <v-col>
        <p>
          Go to Deliverect to connect your restaurant. When you are done, press the
          button below to check that it's been sync'd.
        </p>
        <v-btn class="mr-2" color="primary" :loading="loading" @click="fetchSync">
          <v-icon class="mr-2">mdi-sync</v-icon>
          Check Sync
        </v-btn>
      </v-col>
    </v-row>
    <v-alert v-if="mainSuccessMessage" type="success" outlined class="my-6">
      {{ mainSuccessMessage }}
    </v-alert>
    <v-alert v-if="mainErrorMessage" type="error" outlined class="my-6">
      {{ mainErrorMessage }}
    </v-alert>
    <v-row v-show="restaurantType === 'manual' || manualEdit" class="mt-3">
      <v-col>
        <restaurants-form
          :id="currentRestaurant.id"
          ref="form"
          hide-images
          @update="restaurantManuallyAdded = true"
        />
      </v-col>
    </v-row>
    <sync-dialog
      v-model="restaurantToSync"
      skipConfirmation
      :needsRefresh="needsRefresh"
      :needsRefreshNew="needsRefreshNew"
      @needsRefresh="needsRefresh = $event"
      @needsRefreshNew="needsRefreshNew = $event"
    />
    <onboarding-footer :next="isValid" @submit="submitData" />
  </div>
</template>

<script>
import OnboardingFooter from "../OnboardingFooter.vue"
import RestaurantsForm from "@/AuthenticatedContent/shared/forms/RestaurantsForm.vue"
import SyncDialog from "@/AuthenticatedContent/shared/dialogs/SyncDialog.vue"
import syncMixins from "@/AuthenticatedContent/shared/mixins/syncMixins.js"
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"

export default {
  name: "onboarding-restaurants-restaurant",
  components: { RestaurantsForm, SyncDialog, OnboardingFooter },
  mixins: [syncMixins],
  data() {
    return {
      loading: false,
      isValid: false,
      mainSuccessMessage: "",
      mainErrorMessage: "",
      restaurantType: "",
      restaurantManuallyAdded: false,
      manualEdit: false,
    }
  },
  computed: {
    ...mapState(["firebaseRefs", "restaurants", "channels", "settings"]),
    ...mapGetters(["currentOnboarding"]),
    currentRestaurant() {
      if (this.settings.singleBrandEnabled) {
        return this.restaurants[0]
      }
      return this.restaurants.find(
        restaurant => restaurant.id === this.currentOnboarding.restaurantId
      )
    },
  },
  mounted() {
    if (this.currentOnboarding.restaurantId) {
      const restaurant = this.restaurants.find(
        restaurant => restaurant.id === this.currentOnboarding.restaurantId
      )
      this.$store.commit("setItemToEdit", restaurant)
    }
    this.checkComplete()
    this.setRestaurantTypeFromChannel()
  },
  methods: {
    ...mapActions(["fetchRestaurants", "fetchChannels"]),
    ...mapMutations([""]),
    async submitData() {
      if (this.restaurantType === "manual") {
        await this.$refs.form.submitHandle()
      }
    },
    async setRestaurantTypeFromChannel() {
      // determine restaurant type by checking if the related channel exists
      const channel = this.channels.find(
        channel =>
          channel.restaurantData?.findIndex(
            r => r.restaurantId === this.currentRestaurant.id
          ) !== -1
      )
      if (channel && ["square", "deliverect"].includes(channel.posType)) {
        this.restaurantType = channel.posType
      } else {
        const isValid = this.$refs.form?.validateFields()
        if (isValid) {
          this.restaurantType = "manual"
        }
      }
      this.checkComplete()
    },
    async fetchSync() {
      this.loading = true
      await Promise.all([this.fetchRestaurants(), this.fetchChannels()])
      const isComplete = this.checkComplete()

      const syncProvider = this.restaurantType === "square" ? "Square" : "Deliverect"

      if (!isComplete) {
        this.mainSuccessMessage = ""
        this.mainErrorMessage = `Your restaurant does not seem to have been sync'd with ${syncProvider}. Please try again.`
      } else {
        this.mainSuccessMessage = `Your restaurant has been successfully sync'd with ${syncProvider}.`
        this.mainErrorMessage = ""
      }

      this.loading = false
    },
    checkComplete() {
      let isComplete
      switch (this.restaurantType) {
        case "square":
          isComplete = this.channels.some(
            channel =>
              channel.posType === "square" &&
              channel.restaurantData?.findIndex(
                r => r.restaurantId === this.currentRestaurant.id
              ) !== -1
          )
          break
        case "deliverect":
          isComplete = this.channels.some(
            channel =>
              channel.posType === "deliverect" &&
              channel.id === this.currentRestaurant.id
          )
          break
        case "manual":
          isComplete = this.$refs.form.validateFields()
          break
      }

      this.isValid = isComplete

      return isComplete
    },
  },
}
</script>
