<template>
  <v-card outlined class="mb-8">
    <v-card-text>
      <component :is="component" ref="restaurantStep" />
    </v-card-text>
  </v-card>
</template>

<script>
import RestaurantsRestaurant from "./RestaurantsRestaurant.vue"
import RestaurantsAdmin from "./RestaurantsAdmin.vue"
import { mapState } from "vuex"

export default {
  name: "onboarding-restaurants",
  components: { RestaurantsRestaurant, RestaurantsAdmin },
  computed: {
    ...mapState(["settings", "currentUserRole"]),
    component() {
      return this.settings.singleBrandEnabled || this.currentUserRole === "Manager"
        ? "RestaurantsRestaurant"
        : "restaurants-admin"
    },
  },
  methods: {
    async submitData() {
      await this.$refs.restaurantStep.submitData()
    },
  },
}
</script>
