<template>
  <v-card outlined class="mb-8">
    <v-card-subtitle>
      Would you like to use Deliverect to publish a menu for all your restaurants?
    </v-card-subtitle>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-radio-group v-model="isDeliverect" row>
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <span v-if="isDeliverect">
          Please go to
          <a target="_blank" href="https://frontend.deliverect.com/">Deliverect</a>
          and add NextGen Kitchens as a POS provider. Then, please sync your
          products, build a menu on Deliverect, and then publish the menu to NextGen
          Kitchens.
        </span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex"

export default {
  name: "onboarding-deliverect",
  data() {
    return {
      isDeliverect: "",
    }
  },
  watch: {
    isDeliverect(value) {
      this.setCurrentOnboardingStepField({
        step: this.$route.params.step,
        field: "valid",
        value: value !== "",
      })
    },
  },
  methods: {
    ...mapMutations(["setCurrentOnboardingStepField"]),
    submitData() {
      //
    },
  },
}
</script>
